import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Cart from "./pages/Cart/Cart";
import Success from "./pages/Success/Success";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:token/:orderId" element={<Cart />} />
        <Route path="/success" element={<Success />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
