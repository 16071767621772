import { client } from ".";

export const getOrderDetail = (orderId, token) =>
  client
    .get(`/Cart/GetCartOrder?orderId=${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);

export const applyCouponCode = (payload, token) =>
  client
    .post(`/Cart/ApplyPromoCode`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);

export const removeCouponCode = (orderId, token) =>
  client
    .post(
      `/Cart/RemovePromoCode/${orderId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const completeOrder = (payload, token) =>
  client
    .post(`/Payment/ProcessPaypalOrder/`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
