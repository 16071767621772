import axios from "axios";

const checkStatus = (status) => status === 200;

const client = axios.create({
  //DEV
  baseURL: "https://devapi.sporknmeal.com/api/",
  headers: {
    "Content-type": "Application/json",
    Accept: "Application/json",
  },
  validateStatus: checkStatus,
});

client.interceptors.request.use((response) => {
  return Promise.resolve(response);
});

client.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
    // console.log('api errr', error);
    // console.log('api body>>', JSON.stringify(error?.response?.config?.data));
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (
      error?.response?.data?.error?.message === "Unauthorized" &&
      error?.response?.data?.error?.statusCode === 401
    ) {
    }

    if (error?.message === "Network Error") {
    }

    return Promise.reject(error);
  }
);

export { client };
