import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import "./Cart.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  applyCouponCode,
  completeOrder,
  getOrderDetail,
  removeCouponCode,
} from "../../services/cart";
import { RotatingLines } from "react-loader-spinner";
import { getUserFromToken } from "../../utils";
import { toast } from "react-toastify";

const Cart = () => {
  const navigate = useNavigate();
  const { orderId, token } = useParams();
  const [orderInfo, setOrderInfo] = useState(null);
  const [isFetching, setFetching] = useState(true);
  const [coupon, setCoupon] = useState("");
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    getOrderInfo();
  }, []);

  const getOrderInfo = async () => {
    try {
      setFetching(true);
      const { data } = await getOrderDetail(orderId, token);

      if (data) {
        setOrderInfo(data);
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const handleCouponCode = async (e) => {
    e.preventDefault();
    try {
      if (!isApplied) {
        applyCode();
      } else {
        removeCode();
      }
    } catch (error) {}
  };

  const applyCode = async () => {
    try {
      setFetching(true);

      let { data } = await applyCouponCode(
        {
          orderId,
          promoCode: coupon,
        },
        token
      );
      if (data) {
        setOrderInfo(data);
        setIsApplied(true);
        toast("Coupon Applied Successfully", {
          type: "success",
        });
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
      toast("Invalid Coupon Code", {
        type: "error",
      });
    }
  };

  const removeCode = async () => {
    try {
      setFetching(true);
      let { data } = await removeCouponCode(orderId, token);
      if (data) {
        setOrderInfo(data);
        setIsApplied(false);
        setCoupon("");
        toast("Coupon Removed Successfully", {
          type: "success",
        });
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const handleApprove = async (paymentDetails) => {
    try {
      setFetching(true);
      let response = await completeOrder(
        {
          orderId,
          paymentStatus: "success",
          paymentDetails: { ...paymentDetails, billingToken: "" },
        },
        token
      );

      if (response?.status) {
        navigate("/success");
      }
      setFetching(false);
    } catch (error) {
      console.log("error on complete irdder>>>", error);
      setFetching(false);
    }
  };

  if (isFetching) {
    return (
      <div className="spinner_container">
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_CLIENT_ID,
        components: "buttons",
        currency: "CAD",
      }}
    >
      <div className="container">
        <div className="wrapper">
          <h4>Special Items</h4>
          {/* Order Info Section */}
          {orderInfo?.specialItems?.map((item, index) => {
            return (
              <div key={index}>
                <section className="order_items_wrapper">
                  <div className="provider_wrapper">
                    <span className="provider_name">{item.providerName}</span>
                    {item.itemDetails.map((product, idx) => {
                      return (
                        <div key={idx} className="product_wrapper">
                          <span className="product_name">
                            {product.quantity} {product.itemName}
                          </span>
                          <span className="product_name">
                            ${product.itemTotal?.toFixed(2)}
                          </span>
                        </div>
                      );
                    })}
                    {/* Sub Total */}
                    <div className="product_wrapper">
                      <span className="product_name">Sub Total</span>
                      <span className="product_name">
                        ${item.subTotal?.toFixed(2)}
                      </span>
                    </div>
                    {/* Discount */}
                    <div className="product_wrapper">
                      <span className="product_name">Discount</span>
                      <span className="product_name">
                        ${item.discount?.toFixed(2)}
                      </span>
                    </div>

                    {/* Delivery Charges */}
                    <div className="product_wrapper">
                      <span className="product_name">Delivery Charges</span>
                      <span className="product_name">
                        ${item.deliveryCharges?.toFixed(2)}
                      </span>
                    </div>

                    {/* Marketplace Fee */}
                    <div className="product_wrapper">
                      <span className="product_name">Marketplace Fee</span>
                      <span className="product_name">
                        ${item.platformFee?.toFixed(2)}
                      </span>
                    </div>

                    {/* Tax Charges */}
                    <div className="product_wrapper">
                      <span className="product_name">Tax Charges</span>
                      <span className="product_name">
                        ${item.taxCharges?.toFixed(2)}
                      </span>
                    </div>

                    {/* Delivery Date */}
                    <div className="product_wrapper">
                      <span className="product_name">Delivery Date</span>
                      <span className="product_name">{item.deliveryDate}</span>
                    </div>

                    {/* Delivery Time */}
                    <div className="product_wrapper">
                      <span className="product_name">Delivery Time</span>
                      <span className="product_name">{item.delivieryTime}</span>
                    </div>

                    {/* Service Mode */}
                    <div className="product_wrapper service_mode">
                      <span className="provider_name">Service Mode</span>
                      <span className="product_name">{item.serviceMode}</span>
                    </div>

                    {/* Pickup Address */}
                    {item?.serviceMode === "Pick-up" ? (
                      <div className="pick_up_container">
                        <span className="provider_name">Pickup Address:</span>
                        <span className="pickup_address">
                          {item.pickupAddress}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </section>

                {/* Total  */}
                <section className="total_wrapper total_container">
                  <span>Total</span>
                  <span>${item.total?.toFixed(2)}</span>
                </section>
              </div>
            );
          })}

          {/* Total  */}
          <section className="total_wrapper">
            <span>Total Payable Amount</span>
            <span>${orderInfo?.grandTotal?.toFixed(2)}</span>
          </section>

          {/* Discount */}
          <section className="discount_wrapper">
            <h4>Discount Coupon</h4>
            <form onSubmit={handleCouponCode}>
              <input
                value={coupon}
                required
                placeholder="Discount Coupon"
                onChange={(e) => setCoupon(e.target.value)}
              />
              <button type="submit">{isApplied ? "Remove" : "Apply"}</button>
            </form>
          </section>

          <div className="paypal_container">
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order
                  .create({
                    // intent: "CAPTURE",
                    purchase_units: [
                      {
                        amount: {
                          // currency_code: "CAD",
                          value: orderInfo?.grandTotal?.toFixed(2),
                        },
                        custom_id: getUserFromToken(token)?.unique_name,
                        invoice_id: orderId,
                      },
                    ],
                    application_context: {
                      shipping_preference: "NO_SHIPPING",
                    },
                  })
                  .then((orderId) => {
                    // Your code here after create the order
                    return orderId;
                  });
              }}
              onApprove={function (data, actions) {
                return actions.order.capture().then(function () {
                  // Your code here after capture the order
                  console.log("approve data>>", data);
                  if (data?.orderID) {
                    handleApprove(data);
                  }
                });
              }}
              onCancel={(data, actions) => {
                console.log("cancel>>>>", data, actions);
                toast("Payment cancel by user", {
                  type: "error",
                });
              }}
              onError={(error) => {
                console.log("error>>>>", error);
                // onError();
              }}
            />
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default Cart;
